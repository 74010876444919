import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Select,
  Divider,
} from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { RollbackOutlined, PlusCircleOutlined } from '@ant-design/icons';

const SupplierDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [dataform] = Form.useForm();
  const [editData, setEditData] = useState(null);
  // Disabled : { mapping Fabric to supplier is not needed}
  // const [fabricList, setfabricList] = useState([]);

  const [saving, setSaving] = useState(false);

  const [isLoading, setIsLoading] = useState(null);

  const authToken = getToken();

  //   console.log(location, 'line no 69');
  //   return;

  useEffect(() => {
    console.log(location, 'line no 40');
    if (location.state === null) {
    } else {
      setIsLoading(true);
      setEditData({
        supplierName: location.state?.supplierName,
        supplierDescription: location.state?.supplierDescription,
        supplierType: location.state?.supplierType,
        addressDescription: location.state?.address?.addressDescription,
        country: location.state?.address?.country?.countryName,
        state: location.state?.address?.state?.stateName,
        pincode: location.state?.address.pincode,
        city: location.state?.address?.city?.cityName,
        street: location.state?.address?.street,
        status: location.state?.status,
        contactPerson: location.state?.contactPerson,
        contactPhone: location.state?.contactPhone,
        contactEmail: location.state?.contactEmail,
        gstNo: location.state?.gstNo,
        fabrics:
          location.state?.fabrics?.map((fabric) => fabric?.fabricRefId) || [],
        editId: location.state.id,
      });
      setIsLoading(false);
    }
    // Disabled : { mapping Fabric to supplier is not needed}
    // fetchFabricList(authToken);
  }, []); // setting the required data for the form based on edit / create request.

  useEffect(() => {
    console.log(editData);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]); // set form fields on change in editdata or dataform.

  if (isLoading) {
    return <Spin size="large" />;
  }

  // Disabled : { mapping Fabric to supplier is not needed}
  // const fetchFabricList = async (token) => {
  //   let fabricData2 = [];
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(`${API}/fabrics?`, {
  //       headers: { Authorization: `${BEARER} ${token}` },
  //     });
  //     const responseData = await response.json();
  //     console.log(responseData);
  //     if (responseData.error) {
  //       message.error('Something went wrong, please try again');
  //     } else {
  //       fabricData2 = responseData.data.map((entry) => {
  //         return {
  //           id: entry.id,
  //           fabricName: entry.attributes.fabricName,
  //           fabricQuality: entry.attributes.fabricQuality,
  //           fabricCode: entry.attributes.fabricCode,
  //         };
  //       });

  //       setfabricList(fabricData2);

  //       console.log('original fabric Data:', responseData);
  //       console.log('modified fabric Data:', fabricData2);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     message.error('Error while fetching Data!');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }; // drop down options for fabrics

  const addNewRecord = async (data) => {
    setSaving(true);
    const values = {
      ...data,
      address: {
        addressDescription: data.addressDescription,
        country: data.country,
        state: data.state,
        street: data.street,
        pincode: data.pincode,
        city: data.city,
      },
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/suppler-masters/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          if (responseData?.error?.status == 403) {
            message.error(
              'Unauthorized Access, You don’t have permission to perform this action!'
            );
          } else {
            message.error('Something went wrong, please try again');
          }
          // message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/suppler-masters/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          if (responseData?.error?.status == 403) {
            message.error(
              'Unauthorized Access, You don’t have permission to perform this action!'
            );
          } else {
            message.error('Unable to Add Record, ' + responseData.error);
          }
          // message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const toPreviousPage = () => {
    navigate(-1);
  };

  // Disabled : { mapping Fabric to supplier is not needed}
  // const fabricListOptions = fabricList.map((fabric) => {
  //   return {
  //     label: fabric.fabricName,
  //     value: fabric.id,
  //   };
  // });

  return (
    <div>
      <div>Supplier Master Entry</div>

      <Button type="link" onClick={toPreviousPage}>
        Back
        <RollbackOutlined />
      </Button>

      <div>
        <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
          <Row gutter={[16, 16]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Supplier Name"
                name="supplierName"
                rules={[
                  {
                    required: true,
                    message: 'Supplier Name is Required!',
                  },
                ]}
              >
                <Input placeholder="Supplier Name" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Supplier Description"
                name="supplierDescription"
              >
                <Input placeholder="Supplier Description" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Supplier Type"
                name="supplierType"
                rules={[
                  {
                    required: true,
                    message: 'Supplier Type is Required!',
                  },
                ]}
              >
                <Select placeholder="Select Supplier Type">
                  <Select.Option value="mill">Mill</Select.Option>
                  <Select.Option value="factory">Factory</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Contact Person"
                name="contactPerson"
                rules={[
                  {
                    message: 'Contact Person is must be in text!',
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Contact Person" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Contact Phone"
                name="contactPhone"
                rules={[
                  {
                    message: 'Contact Phone must be a number!',
                    type: 'decimal',
                  },
                ]}
              >
                <Input placeholder="Contact Phone" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Status" name="status" initialValue={true}>
                <Select placeholder="Select Status">
                  <Select.Option value={true}>Active</Select.Option>
                  <Select.Option value={false}>Not Active</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Contact Email"
                name="contactEmail"
                rules={[
                  {
                    // required: true,
                    // message: 'Contact Email!',
                  },
                ]}
              >
                <Input type="email" placeholder="Enter Contact Email" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="GST No"
                name="gstNo"
                rules={[
                  {
                    // required: true,
                    // message: 'GST No is Required!',
                  },
                ]}
              >
                <Input placeholder="GST No." />
              </Form.Item>
            </Col>

            {/* Disabled : { mapping Fabric to supplier is not needed} */}
            {/* <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Fabrics supplies" name="fabrics">
                <Select
                  placeholder="Select "
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.trim().toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={fabricListOptions}
                />
              </Form.Item>
                </Col> */}
          </Row>

          <Divider orientation="left">Address</Divider>

          <Row gutter={[16, 16]} className="AddressSection">
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Address Description"
                name="addressDescription"
                rules={[
                  {
                    // // required: true,
                    // message: 'Address Description is Required!',
                  },
                ]}
              >
                <Input placeholder="street" />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Street"
                name="street"
                rules={[
                  {
                    // // required: true,
                    // message: 'Street is Required!',
                  },
                ]}
              >
                <Input placeholder="Street Name" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Pincode"
                name="pincode"
                rules={[
                  {
                    // // required: true,
                    // pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input placeholder="Pincode" />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="country"
                name="country"
                rules={[
                  {
                    // // required: true,
                    // message: 'Country is Required!',
                  },
                ]}
              >
                <Input placeholder="Country Name" />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="state"
                name="state"
                rules={[
                  {
                    // // required: true,
                    // message: 'State Name',
                  },
                ]}
              >
                <Input placeholder="State Name" />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="city"
                name="city"
                rules={[
                  {
                    // // required: true,
                    // message: 'city is Required!',
                  },
                ]}
              >
                <Input placeholder="City Name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="editId" hidden={true} name="editId">
            <Input type="text" />
          </Form.Item>
          <Button
            className="addProduct_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {saving ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default SupplierDetails;
